.comparison-section {
    display: flex;

    &__print,
    &__email {
        width: 32px;
        height: 32px;

        .icon {
            position: absolute;
        }
    }

    &__email {
        margin-top: 8px;
    }

    &__wrapper {
        flex-grow: 2;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__buttons {
        display: flex;
        flex-direction: column;
    }
}

.comparison-list {
    display: inline-flex;
    width: 100%;
    flex-grow: 1;
}

.comparison .accordion__title {
    display: initial;
}

.comparison-table--top th .comparison-section__print.button {
    width: 100%;
    justify-content: left;
    margin-bottom: 10px;

    .icon {
        position: relative;
    }
}

.comparison-form {
    width: 90%;
}

.comparison-table .comparison-product-card {
    padding-left: 0;
}

#sendComparisonForm > * {
        margin-top: 10px;
}