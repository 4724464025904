@use "../../variables";

.comparison-table {
    width: 100%;
    border: 0;
    border-spacing: 0;
    border-collapse: collapse;
    table-layout: fixed;
    
    th {
        text-align: left;
        color: variables.$neutral-darker;
        font-size: variables.$font-size-sm;
        text-indent: 0;

        .checkbox {
            font-weight: variables.$font-weight-normal;
        }
    }

    td {
        font-size: variables.$font-size-sm;
        padding: 5px;
        word-wrap: break-word;
        line-height: variables.$paragraph-lh;
        color: variables.$neutral-darker;

        &.product-cards {
            padding: 0;
        }
    }

    .td-buttons {
        display: flex;
        flex-direction: column;


        .price {
            font-size: variables.$font-size-xl;
            color: variables.$neutral-darker;
            font-weight: variables.$font-weight-bold;

            &--discount {
                color: variables.$supporting-01;
            }
        }
    }

    &--top {
        position: relative;

        .td-buttons {
            margin: 24px 25px 0 25px;
            max-width: 178px;
        }

        .product-card {
            min-height: 355px;
            border-style: solid;
            box-shadow: none;
        }

        th {
            height: 0;

            .button {
                min-width: unset;
            }
        }

        .no-padding {

            td {
                padding: 0;
            }
        }

        .td-buttons {

            .button {
                padding: 0;
            }
        }
    }


    &--bottom {

        .comparison-table__row {


            &:not(:last-child) {
                border-bottom: 1px solid variables.$neutral-lighter-50;
            }

            &:hover {
                background: variables.$neutral-lighter-25;
            }

            &--difference {

                &:hover {
                    background: variables.$neutral-lighter-100;
                }

                th,
                td {
                    background: variables.$neutral-lighter-25;

                    &:hover {
                        background: variables.$neutral-lighter-100;
                    }
                }
            }
        }
    }

    .checkbox__input:checked ~ .checkbox__label:after {
        top: 8px;
    }

    label.checkbox__label {
        line-height: 1.8;
    }

    .reCaptcha > div {
        margin: auto;
    }
}

.available {
    color: variables.$supporting-03;
    font-size: variables.$font-size-default;
}

.unavailable {
    font-size: variables.$font-size-default;
    color: variables.$supporting-01-dark;
}

.comparison-product-card {
    .product-card {
        &:hover:not(.landscape) {
            margin-top: inherit;
            border-left: inherit;
            box-shadow: inherit;
            border: 0;
        }

        @if variables.$site-nova {
            box-shadow: none;
        }
    }

    .product-card__comparison.favorite {
        z-index: 1;
        top: 20px;
    }
}

.discount-overlay--right {
    top: 110px;
}

button.button.comparison-section__print.button--tertiary.button--small {
    max-width: 225px;
}

.accordion .comparison-table th {
    padding-left: 10px;
}

.comparison-sticky .checkbox__input:checked ~ .checkbox__label:after {
    top: 8px;
}

/* SEND COMPARISON */
.send-comparison {
    width: 240px;
}

/* PRODUCT CARDS */
.product-card-container {
    display: flex;

    .product-card__item {
        width: 300px;
        padding: 0;

        &__outer {
            padding: 8px 12px 20px 12px;
            margin: 0 4px;
            border: 1px solid variables.$neutral-lighter-100;
            border-radius: 8px;

            .btn-remove {
                display: block;
                top: -23px;
                margin-left: auto;
                cursor: pointer;
            }

            .btn-addtocart {
                width: 100%;
                margin: 8px 0 0 0;
            }
        }

        .product-card {
            padding: 0;
            margin: auto;
            border: 0;
        }
    }
}

.product-card__overlays {
    top: 0;
    left: -12px;
    
}


/* DIFFERENCE DIV */
.difference-div, .difference-sticky-div .checkbox {
    padding-top: 14px;
    padding-left: 24px;
}

/* PARAMETERS */
.comparison .accordion {
    margin-top: 24px;

    .accordion__item {
        margin: 8px 0;

        .accordion__title {
            background-color: variables.$primary-lighter-10;
            height: 38px;
            width: auto;
            padding-left: 20px;
            display: block;
            line-height: 38px;
        }

        &--active:last-of-type {
            .accordion__title {
                border: 0;
            }
        }

        &--active {
            .accordion__title {
                border: 0;
            }
        }
    }
}

.parameter-values-container {
    display: flex;
}

.parameter-title {
    font-weight: 600;
    width: 240px;
    text-align: left;
    color: variables.$neutral-darker;
    position: sticky;
    left: 24px;
}

.parameter-values {
    display: flex;
}

.parameter-values__item {
    width: 240px;
    padding-left: 4px;
}

/* RESPONSIVE */
@media (max-width: variables.$responsive-break - 1) {
    #comparison-container {
        overflow-x: scroll;

        #extra-container {
            width: 100%;
            display: block;
            margin: auto;

            .category__header {
                text-align: center;
                color: #1D428A;
                font-weight: 400;
                left: 50%;
                position: absolute;
                transform: translate(-50%, -50%);
                top: 95px;
            }

            .accordion .accordion__item {
                overflow: initial;

                .accordion__title > * {
                    position: sticky;
                    left: 20px;
                }
            }

            .accordion, .comparison-table, .comparison-sticky__inner-container {
                width: max-content;
                display: block;
                margin: auto;
            }
        }
    }

    .product-card__item {

        &__outer {
            padding: 8px 8px 20px 8px;
            margin: 0 4px;
        }
    }

    .send-comparison {
        width: 0;
        display: none;

        > div {
            display: none;
        }
    }

    .difference-div {
        width: max-content;
        margin: auto;
        height: 30px;
        margin-top:50px;
        .checkbox{
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .difference-sticky-div {
        display: none;
    }

    .comparison-sticky__item {
        width: 230px;
    }
    .parameter-values-container {
        display: block;
    }

    .parameter-title {
        left: 0;
        padding-left: 4px;
    }
    .parameter-values__item {
        width: 300px;
    }
}